import React, { useMemo } from 'react'
import compact from 'just-compact'
import { SeenInStrip } from '@heights/heights-ui-components'
import { withErrorBoundary } from '@/utils'
import { transformImage } from '@/utils/contentful'
import { textItem as textItemSchema } from '@/schemas/textItem'
import { sectionDataHookFactory } from '@/hooks'
import type { SingleComponentPickerItem } from '../../SingleComponentPicker'
import { asResolverQuery } from '@/utils/groq'

type Props = {
  data: SingleComponentPickerItem
}

const [textItemQuery, textItemType] = textItemSchema.builder
  .map((item) => ({
    image: item.fields.image?.resolve(['fields']).use(),
  }))
  .use()

const useSectionData = sectionDataHookFactory({
  textItems: asResolverQuery<typeof textItemType>(
    'fields.components',
    textItemQuery,
    '@->_type == "textItem"'
  ),
})

export const ContentfulSectionSeenInStrip: React.FC<Props> = withErrorBoundary(
  ({ data }) => {
    const section = useSectionData(data._id)

    if (!section) {
      return null
    }

    const logos = useMemo(
      () =>
        compact(
          section?.textItems?.map((textItem) => {
            const image = transformImage(textItem?.image?.fields, {
              width:
                textItem?.image?.fields?.file?.details?.image?.width ?? 194,
              height: 18,
              objectFit: 'contain',
              objectPosition: 'center',
            })
            return image
              ? {
                  image,
                  quote: textItem?.fields?.plainText,
                }
              : null
          }) ?? []
        ),
      [section?.textItems]
    )
    if (!logos?.length) {
      return null
    }

    return (
      <SeenInStrip
        title={section.fields?.title}
        logos={logos}
        backgroundColor={section.fields?.backgroundColor ?? undefined}
      />
    )
  }
)

export default ContentfulSectionSeenInStrip
